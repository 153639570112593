import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FlashBanner = props => {
  const { children, className, onClose, type, yesHtmlIsSanitized } = props;

  const [isRendered, setIsRendered] = useState(true);

  const flashTypeClass = () => {
    switch (type) {
      case 'success':
      case 'notice':
      case 'persistent_notice':
        return 'alert-success';
      case 'error':
      case 'alert':
        return 'alert-danger';
      case 'info':
        return 'alert-info';
      default:
        return `${type ?? ''} alert-info`;
    }
  };

  const handleCloseClick = () => {
    setIsRendered(false);
    if (onClose) {
      onClose();
    }
  };

  if (isRendered) {
    return (
      <div
        className={`alert ${flashTypeClass()} alert-dismissible ${className ??
          ''}`}
      >
        <button className="close" onClick={handleCloseClick}>
          ×
        </button>
        {typeof children === 'string' && yesHtmlIsSanitized ? (
          <div dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          <div>{children}</div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

FlashBanner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
  yesHtmlIsSanitized: PropTypes.bool
};

export default FlashBanner;
