import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import FlashBanner from '../FlashBanner/FlashBanner';

// the `flashes` array from Rails may contain other data not meant to be displayed as
// a flash message, so these are the actual message types we expect
const ALLOWED_FLASH_TYPES = [
  'success',
  'error',
  'alert',
  'notice',
  'info',
  'info_no_cancel',
  'persistent_notice'
];

const FlashMessages = props => {
  const { container, flashes, onClose } = props;

  const handleBannerClose = type => {
    if (type === 'persistent_notice') {
      let inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
      Cookies.set(`dismissed_${Cookies.get('persistent_banner')}`, true, {
        expires: inTenMinutes
      });
    }
  };

  return (
    <div className={container ? 'container' : ''}>
      {flashes?.map((flash, index) => {
        if (ALLOWED_FLASH_TYPES.includes(flash.type) && flash.message) {
          return (
            <FlashBanner
              key={index}
              type={flash.type}
              onClose={() => {
                handleBannerClose(flash.type);
                if (onClose) onClose(flash, index);
              }}
              yesHtmlIsSanitized
            >
              {flash.message}
            </FlashBanner>
          );
        }
      })}
    </div>
  );
};

FlashMessages.defaultProps = {
  container: true
};

FlashMessages.propTypes = {
  container: PropTypes.bool,
  flashes: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, message: PropTypes.string })
  ),
  onClose: PropTypes.func
};

export default FlashMessages;
