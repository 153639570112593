import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import fuzzySearch from '../../../lib/fuzzySearch';

const OrganizationInput = props => {
  const {
    onSelect,
    helpText,
    testId,
    showRequiredIndicator,
    label,
    id,
    excludeOrgId,
    placeholder,
    required,
    name,
    searchUrl,
    value,
    hasErrors,
    customErrorMessage,
    removeParentOption,
    autoComplete
  } = props;

  const [organizationResults, setOrganizationResults] = useState([]);
  const [
    filteredOrganizationResults,
    setFilteredOrganizationResults
  ] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(hasErrors);

  const items = () => {
    return filteredOrganizationResults.map(item => {
      return { ...item, type: 'organizationResult', inputValue: item.name };
    });
  };

  const getFilteredOrganizationList = fieldValue => {
    let filtered = organizationResults.filter(org => {
      // if an org is being excluded, don't include it when filtering
      if (org.id === excludeOrgId) return false;
      return fuzzySearch(fieldValue, org.name);
    });
    filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (removeParentOption === true) {
      filtered.unshift({
        id: 0,
        name: 'No Parent',
        type: 'organizationResult',
        inputValue: 'No Parent'
      });
    }
    return filtered.slice(0, 100);
  };

  const renderItem = (item, isHighlighted) => {
    const highlightedClass = isHighlighted ? 'active' : '';
    return (
      <div
        className={`autocomplete-menu-item autocomplete-menu-selectable ${highlightedClass}`}
        key={item.id}
      >
        <div>
          <div className="autocomplete-menu-item-icon" />
          <div className="autocomplete-menu-item-contents">
            <span>{item.name}</span>
          </div>
        </div>
      </div>
    );
  };

  const onFocus = e => {
    handleChange(e);
  };

  const onBlur = () => {
    // we only want to process this logic if there are no results
    if (items() && items().length > 0) {
      return;
    }

    // tell the caller that nothing was selected
    handleSelect(inputValue, undefined);
  };

  const handleChange = e => {
    const fieldValue = e.target.value;
    if (!fieldValue || fieldValue.length < 3) {
      setInputValue(fieldValue);
      setFilteredOrganizationResults([]);
      return;
    }

    const filtered = getFilteredOrganizationList(fieldValue);
    setInputValue(fieldValue);
    setFilteredOrganizationResults(filtered);
  };

  const handleInvalid = e => {
    if (customErrorMessage) {
      e.preventDefault();
    }
    setIsInvalid(true);
  };

  const handleSelect = (value, item) => {
    setInputValue(value);
    if (item) {
      onSelect(item.id, item.name, item.druid);
      setIsInvalid(false);
    } else {
      onSelect('', '', '');
      if (required) {
        setIsInvalid(true);
      }
    }
  };

  const renderMenu = renderedItems => {
    // Return empty div if there's nothing to see
    if (!renderedItems || renderedItems.length < 1) {
      return <div />;
    }

    return (
      <div className="autocomplete-menu org-referral-autocomplete-menu">
        {renderedItems}
      </div>
    );
  };

  useEffect(() => {
    fetch(searchUrl || '/admin/organizations/referral_filter', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(results => {
        return results.json();
      })
      .then(data => {
        setOrganizationResults(
          data.map(item => {
            return {
              ...item,
              type: 'organizationResult',
              inputValue: item.name
            };
          }) || []
        );
      });
  }, []);

  // Update value if it is updated externally
  useEffect(() => {
    if (value !== inputValue) {
      if (value === null || typeof value === 'undefined') {
        setInputValue('');
      } else {
        setIsInvalid(false);
        setInputValue(value);
      }
    }
  }, [value]);

  return (
    <div
      className={`mb-6 last:mb-0 ${isInvalid ? 'has-error' : ''}`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label htmlFor={id}>
        {required && showRequiredIndicator && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
      </label>
      <Autocomplete
        className="form-control"
        getItemValue={item => {
          return item.inputValue;
        }}
        items={filteredOrganizationResults}
        renderItem={renderItem}
        inputProps={{
          className: 'form-control',
          id: id,
          name: name,
          placeholder: placeholder || 'Start typing...',
          onFocus: onFocus,
          onBlur: onBlur,
          onInvalid: handleInvalid,
          required: required
        }}
        renderInput={props => {
          return (
            <input
              {...props}
              autoComplete={autoComplete || 'disable-autocomplete'}
            />
          ); // eslint-disable-line
        }}
        wrapperStyle={{}}
        wrapperProps={{ className: 'autocomplete' }}
        value={inputValue}
        onChange={handleChange}
        onSelect={handleSelect}
        renderMenu={renderMenu}
        autoHighlight={true}
        selectOnBlur={true}
      />
      {isInvalid && customErrorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {customErrorMessage}
        </div>
      )}
      {helpText && (
        <div
          className="help-block"
          data-testid={testId ? `${testId}-helptext` : null}
        >
          {helpText}
        </div>
      )}
    </div>
  );
};

OrganizationInput.propTypes = {
  onSelect: PropTypes.func,
  helpText: PropTypes.string,
  testId: PropTypes.string,
  showRequiredIndicator: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  excludeOrgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  searchUrl: PropTypes.string,
  value: PropTypes.string,
  hasErrors: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  removeParentOption: PropTypes.bool,
  autoComplete: PropTypes.string
};

OrganizationInput.defaultProps = {
  removeParentOption: false,
  showRequiredIndicator: true
};

export default OrganizationInput;
